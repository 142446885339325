<app-button buttonType="text" icon="icon-arrow-l" (clickit)="goBack()">
	Terug naar
	@if (bestuurId) {
		Bestuur
	} @else {
		{{ enviromentName }}
	}
</app-button>

@if (instelling$ | async; as instelling) {
	<div class="header">
		<h2>{{ instelling.naam }}</h2>
		<app-instelling-labels [instelling]="instelling"></app-instelling-labels>
		<div class="uuid">{{ instelling.uuid }}</div>
		<div class="right buttons">
			@if (instelling.actief) {
				@if (instelling.bron === InstellingBron.Magister) {
					<app-button buttonType="text" icon="icon-settings" (clickit)="magisterSettings()">Instellingen</app-button>
				}
				<app-button buttonType="secondary" (clickit)="deactivateInstelling(instelling)">Instelling deactiveren</app-button>
			} @else {
				<app-button buttonType="secondary" (clickit)="deleteInstelling(instelling)">Instelling verwijderen</app-button>
				<app-button buttonType="primary" (clickit)="activateInstelling(instelling)">Instelling activeren</app-button>
			}
		</div>
	</div>

	<div class="cards">
		<div class="row">
			<div class="column">
				<div class="card login">
					<h3>Inloggen als</h3>
					@if (accountOptions$ | async; as accountOptions) {
						<dt-form-dropdown
							[opties]="filterOptions(accountOptions, accountZoekFilter())"
							[selectedOptie]="findOption(accountOptions, selectedAccount())"
							(onOptieClicked)="selectedAccount.set($event); accountZoekFilter.set('')"
							[showSearchInput]="true"
							[searchInput]="accountZoekFilter()"
							(onSearchInput)="accountZoekFilter.set($event)"
							[enabled]="instelling.actief!"
						>
						</dt-form-dropdown>
						<app-button
							(clickitCtrl)="impersonate(selectedAccount()!, '_self')"
							(clickit)="impersonate(selectedAccount()!, '_blank')"
							[enabled]="selectedAccount() !== undefined"
							[ctrlClickEnabled]="true"
						>
							Inloggen
						</app-button>
					}
				</div>
				<div class="card data">
					<h3>Dataverwerking</h3>
					@if (!instelling.actief) {
						<div>
							<span>Instelling is gedeactiveerd. Er vindt geen dataverwerking plaats.</span>
						</div>
					} @else if (!instelling.instellingETLStatus) {
						<div>
							<span>Er heeft nog geen dataverwerking plaatsgevonden.</span>
						</div>
					} @else {
						<div
							class="etl"
							[class.etl-bezig]="etlBezigStatussen.includes(instelling.instellingETLStatus)"
							[class.etl-failed]="instelling.instellingETLStatus === InstellingETLStatus.GEFAALD"
							[class.etl-succesvol]="instelling.instellingETLStatus === InstellingETLStatus.GESLAAGD"
						>
							<div
								[class.svg-gepland]="instelling.instellingETLStatus === InstellingETLStatus.GEPLAND"
								[class.svg-bezig]="instelling.instellingETLStatus === InstellingETLStatus.BEZIG"
								[class.svg-error]="instelling.instellingETLStatus === InstellingETLStatus.GEFAALD"
								[class.svg-etl-succesvol]="instelling.instellingETLStatus === InstellingETLStatus.GESLAAGD"
							></div>
							<div class="message">
								<span>{{ instelling.laatsteRun | date: format }}</span>
								@switch (instelling.instellingETLStatus) {
									@case (InstellingETLStatus.GEPLAND) {
										<span>Nieuwe ETL verwerking gepland.</span>
									}
									@case (InstellingETLStatus.BEZIG) {
										<span>Nieuwe ETL verwerking bezig.</span>
										<span>Laatst succesvolle verwerking: {{ instelling.laatsteSuccesvolleRun | date: format }}</span>
									}
									@case (InstellingETLStatus.GEFAALD) {
										<span>Dataverwerking mislukt.</span>
										@if (isDefaultStartDatum(instelling.laatsteSuccesvolleRun!)) {
											<span>Er heeft nog geen eerdere succesvolle verwerking plaatsgevonden.</span>
										} @else {
											<span>Laatst succesvolle verwerking: {{ instelling.laatsteSuccesvolleRun | date: format }}</span>
										}
									}
									@case (InstellingETLStatus.GESLAAGD) {
										<span>Dataverwerking succesvol.</span>
									}
								}
							</div>
						</div>
					}
				</div>
			</div>
			<div class="column">
				<div class="row wrap">
					<app-licenties-card [instelling]="instelling" (instellingChanged)="refreshInstelling$.next()"></app-licenties-card>
					<app-modules-card [instelling]="instelling" (instellingChanged)="refreshInstelling$.next()"></app-modules-card>
					<div class="card etl-runs">
						<div class="etl-card-header">
							<h3>Laatste runs</h3>
							<app-toggle-buttons
								[options]="options"
								[value]="selectedOption()"
								(valueChange)="selectedOption.set($event)"
							></app-toggle-buttons>
						</div>
						<table>
							<thead>
								<tr>
									<th>Datum</th>
									<th>Doorlooptijd</th>
									<th>Status</th>
									<th>Gefaalde stap</th>
								</tr>
							</thead>
							<tbody>
								@if (getJobruns(instelling, selectedOption()); as jobruns) {
									@for (jobrun of jobruns; track jobrun.datumTijd; let last = $last) {
										<tr [class.bezig]="jobrun.status === InstellingETLStatus.BEZIG">
											<td>{{ jobrun.datum }}</td>
											<td class="duration">{{ jobrun.doorlooptijd }}</td>
											<td>{{ jobrun.status }}</td>
											<td>{{ jobrun.gefaaldeStap }}</td>
										</tr>
										@if (last) {
											<tr class="gemiddelde">
												<td>Gemiddeld</td>
												<td class="duration">{{ jobrun.avgDoorlooptijd }}</td>
												<td></td>
												<td></td>
											</tr>
										}
									}
								}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
}

<ng-template #confirmInstellingDeactiveren let-data>
	<app-confirm-dialog caption="School deactiveren" [singleAction]="data.bron === InstellingBron.Somtoday">
		@if (data.bron === InstellingBron.Magister) {
			Je staat op het punt om <b>{{ data.naam }}</b> te deactiveren. Gebruikers van deze instelling zullen dan geen toegang meer hebben tot
			CumLaude. Weet je dit zeker?
		} @else {
			Het deactiveren van <b>{{ data.naam }}</b> kan door met het support account in te loggen in de
			<a [href]="somtodayUrl">desbetreffende Somtoday omgeving</a>. Zet in Somtoday beheer de feature CumLaude uit om de school te deactiveren.
		}
	</app-confirm-dialog>
</ng-template>

<ng-template #confirmInstellingHeractiveren let-data>
	<app-confirm-dialog caption="School activeren" [singleAction]="data.bron === InstellingBron.Somtoday">
		@if (data.bron === InstellingBron.Magister) {
			Je staat op het punt om <b>{{ data.naam }}</b> te heractiveren. Alle gebruikers van deze instelling hebben dan weer toegang tot CumLaude.
			Weet je het zeker?
		} @else {
			Het heractiveren van <b>{{ data.naam }}</b> kan door met het support account in te loggen in de
			<a [href]="somtodayUrl">desbetreffende Somtoday omgeving</a>. Zet in Somtoday beheer de feature CumLaude aan om de school te heractiveren.
		}
	</app-confirm-dialog>
</ng-template>

<ng-template #confirmInstellingVerwijderen let-data>
	<app-confirm-dialog caption="School activeren">
		Je staat op het punt om <b>{{ data.naam }}</b> te verwijderen. Alle data en accounts van deze instelling zullen permanent worden verwijderd
		uit CumLaude. Weet je het zeker?
	</app-confirm-dialog>
</ng-template>
