import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InstellingETLStatus, RInstelling, RInstellingAdditionalObjectKey } from '@cumlaude/service-contract';
import { InstellingLabelsComponent } from '../instelling-labels/instelling-labels.component';
import { CheckboxComponent } from '@cumlaude/shared-components-inputs';
import { isUndefined } from 'lodash-es';

@Component({
	selector: 'app-instellingen-list',
	templateUrl: './instellingen-list.component.html',
	styleUrl: './instellingen-list.component.scss',
	standalone: true,
	imports: [InstellingLabelsComponent, CheckboxComponent],
})
export class InstellingenListComponent implements OnInit {
	etlIconStatussen: InstellingETLStatus[] = [InstellingETLStatus.GEPLAND, InstellingETLStatus.BEZIG, InstellingETLStatus.GEFAALD];

	@Input()
	instellingen!: RInstelling[];

	@Input()
	checked?: RInstelling[];

	@Input()
	disabled?: RInstelling[];

	@Input()
	uuidEnabled = false;

	@Input()
	warningIconEnabled = false;

	@Input()
	statusEnabled = false;

	@Input()
	toonBestuurLabel = true;

	@Input()
	bestuurLicentiesEnabled = false;

	@Input()
	totaalEnabled = false;

	checkboxEnabled = false;

	@Output()
	instellingClick = new EventEmitter<RInstelling>();

	ngOnInit() {
		this.checkboxEnabled = !isUndefined(this.checked);
	}

	getStatus(instelling: RInstelling): { checked: boolean; disabled: boolean; instellingETLStatus: InstellingETLStatus | undefined } {
		return {
			disabled: this.disabled?.some((target) => target.id === instelling.id) ?? false,
			checked: this.checked?.some((target) => target.id === instelling.id) ?? false,
			instellingETLStatus: instelling.actief && this.warningIconEnabled ? instelling.instellingETLStatus : undefined,
		};
	}

	getLicenties(instelling: RInstelling): number | undefined {
		return instelling.additionalObjects![RInstellingAdditionalObjectKey.LICENTIES].bestuur?.inGebruik;
	}

	getTotaalLicenties() {
		return this.instellingen
			.map((instelling) => this.getLicenties(instelling))
			.filter((licenties): licenties is number => !!licenties)
			.reduce((a, b) => a + b, 0);
	}

	protected readonly InstellingETLStatus = InstellingETLStatus;
}
